import { template as template_cbf4307c939547b8b7518cd4a75f2566 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cbf4307c939547b8b7518cd4a75f2566(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
